// 引入工具函数
import { http,httpimg } from '../../api/index'


// 分页查询
export const querySchedulingPeriod = params => http.get('/ohealth/api/v1/isolation/schedulingPeriod/list', params)

// 获取list
export const getSchedulingPeriodList = (params) => http.post('/ohealth/api/v1/isolation/schedulingPeriod/getSchedulingPeriodList', params)

// 通过id查询
export const querySchedulingPeriodById = id => http.get('/ohealth/api/v1/isolation/schedulingPeriod/info/' + id)

// 新增保存
export const saveSchedulingPeriod = params => http.post('/ohealth/api/v1/isolation/schedulingPeriod/save', params)

// 修改保存
export const updateSchedulingPeriod = params => http.put('/ohealth/api/v1/isolation/schedulingPeriod/update', params)

// 删除
export const deleteSchedulingPeriod = params => http.delete('/ohealth/api/v1/isolation/schedulingPeriod/delete', params)

// 根据id修改状态
export const updateStateById = (id, state) => http.put('/ohealth/api/v1/isolation/schedulingPeriod/updateStateById?id=' + id + '&state=' + state)

// 复制排班信息
export const copyScheduling = params => http.post('/ohealth/api/v1/isolation/scheduling/copyScheduling', params)

// 保存默认排班周日期
export const saveDefaultSchedulingWeek = params => http.post('/ohealth/api/v1/isolation/scheduling/saveDefaultSchedulingWeek', params)

// 导出排班
export const exportScheduling = params => httpimg.post('/ohealth/api/v1/isolation/scheduling/exportScheduling', params)
